"use strict";

var storage = window.sessionStorage || {};

/**
* Generates a hash value from the given input string
* @param {string} input - the input string
* @returns {number} - a 32-bit hash value
*/
function toHash(input) {
    var hval = 0x811c9dc5;

    for (var i = 0; i < input.length; i++) {
        hval = hval ^ (input.charCodeAt(i) & 0xFF);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }

    return hval >>> 0;
}

/**
* Returns an array of state, title, and url
* @param {Array} args - an array of arguments
* @returns {Array} - an array of state, title, and url
*/
function getState(args) {
    if (args.length === 1 && typeof args[0] === "object") {
        var state = args[0].state || {};
        var title = args[0].title || "";
        var url = args[0].url || "";

        return [state, title, decodeURI(url)];
    }

    return args;
}

/**
* Retrieves all page events from local storage
* @returns {Object} an object containing all page events
*/
function getAllPageEvents() {
    try {
        var events = storage.getItem("page_events");
        return events ? JSON.parse(events) : {};
    } catch (error) {
        return {};
    }
}

/**
* PageEvents constructor
*
* @constructor
* @returns {PageEvents} - an instance of PageEvents
*/
function PageEvents() {
    var pageEvents = getAllPageEvents();
    var pageID = toHash(decodeURI(window.location.href));
    var currentPageEvents = pageEvents[pageID] || {};

    this.clicks = currentPageEvents.clicks || [];
    this.lastClickedRegionLink = currentPageEvents.lastClickedRegionLink || null;
    this.lastClickedProduct = currentPageEvents.lastClickedProduct || null;
    this.lastScrolledPosition = currentPageEvents.lastScrolledPosition || null;
    this.pageID = pageID;
}

PageEvents.prototype = {
    /**
    * Adds an element to the clicks array and saves it
    * @param {Object} element - the element to add
    */
    addClick: function (element) {
        if (this.isExists(element)) {
            return;
        }

        this.clicks.push(element);
        this.save();
    },

    /**
    * Checks if an element is present in the clicks array
    * @param {Object} element - the element to check
    * @returns {boolean} - true if the element is present, false otherwise
    */
    isExists: function (element) {
        return this.clicks.some(function (click) {
            return element.eventName == click.eventName && click.url === element.url;
        });
    },

    /**
    * Gets the clicks for the given event name
    * @param {string} name - the event name
    * @returns {Array} - an array of clicks for the given event name
    */
    getClicks: function (name) {
        return this.clicks.filter(function (click) {
            return click.eventName === name;
        });
    },

    /**
    * Saves the last clicked product ID
    * @param {string} productID - the ID of the last clicked product
    */
    setLastClickedProduct: function (productID) {
        this.lastClickedProduct = productID;
        this.save();
    },

    /**
    * Saves the last clicked region link
    * @param {string} link - the last clicked region link
    */
    setLastClickedRegionLink: function (link) {
        this.lastClickedRegionLink = link;
        this.save();
    },

    /**
    * Saves the last scrolled position
    * @param {number} position - the last scrolled position
    */
    setLastScrolledPosition: function (position) {
        this.lastScrolledPosition = position;
        this.save();
    },

    /**
    * Stores page events in local storage
    * @param {string} pageURL - decoded page URL
    * @param {string} pageID - page ID generated from page URL
    * @param {Object} pageEvents - all page events
    * @param {Object} currentPageEvents - page events for the current page
    * @param {Array} clicks - array of click events
    * @param {Object} lastClickedProduct - last clicked product
    * @param {number} lastScrolledPosition - last scrolled position
    * @param {Object} lastClickedRegionLink - last clicked region link
    */
    save: function () {
        var pageURL = decodeURI(window.location.href);
        var pageID = this.pageID || toHash(pageURL);

        var pageEvents = getAllPageEvents();
        var currentPageEvents = pageEvents[pageID];

        if (!currentPageEvents) {
            currentPageEvents = {
                pageID: pageID,
                pageURL: pageURL
            };
        }

        currentPageEvents.clicks = this.clicks || [];
        currentPageEvents.lastClickedProduct = this.lastClickedProduct || null;
        currentPageEvents.lastScrolledPosition = this.lastScrolledPosition || null;
        currentPageEvents.lastClickedRegionLink = this.lastClickedRegionLink || null;
        pageEvents[pageID] = currentPageEvents;

        storage.setItem("page_events", JSON.stringify(pageEvents));
    },
    /**
    * Removes the page event from the page events list
    * @param {string} pageID - ID of the page event to be removed
    */
    clear: function () {
        var pageEvents = getAllPageEvents();

        this.clicks = [];
        this.lastClickedRegionLink = null;
        this.lastClickedProduct = null;
        this.lastScrolledPosition = null;

        delete pageEvents[this.pageID];

        storage.setItem("page_events", JSON.stringify(pageEvents));
    },
    /**
    * Constructor for PageEvents
    * @param {string} pageID - the page ID
    */
    clone: function (pageID) {
        var pageEvents = getAllPageEvents();
        var sourcePageEvents = pageEvents[pageID] || {};

        this.clicks = sourcePageEvents.clicks || [];
        this.lastClickedRegionLink = sourcePageEvents.lastClickedRegionLink || null;
        this.lastClickedProduct = sourcePageEvents.lastClickedProduct || null;
        this.lastScrolledPosition = sourcePageEvents.lastScrolledPosition || null;
        this.save();
    }
};

/**
* Constructor for CurrentPage object
* @constructor
*/
function CurrentPage() {
    this.pageURL = decodeURI(window.location.href);
    this.pageID = toHash(this.pageURL || "");
    this.events = new PageEvents();
}

CurrentPage.prototype = {
    /**
    * This method fetches the data stored in the session storage specific for the current page.
    * @param {string} [key] - the key of the data to retrieve
    * @returns {Object} the data stored in local storage
    */
    get: function () {
        var data = {};
        try {
            data = JSON.parse(storage.getItem(this.pageID));
        } catch (error) {
            data = arguments[0];
        }

        return arguments.length ? data[arguments[0]] : data;
    },

    /**
    * Sets the data to the session storage scoped to the current page
    * @param {string} key - the key of the data to be set
    * @param {string} value - the value of the data to be set
    */
    set: function () {
        var data = this.get() || {};
        for (var i = 0; i < arguments.length; i += 2) {
            data[arguments[i]] = arguments[i + 1];
        }

        storage.setItem(this.pageID, JSON.stringify(data));
    },

    /**
    * Re-initializes the page events
    */
    refresh: function () {
        this.events = this.events || new PageEvents();
        this.events.clear();
    }
};

/**
* Constructor for Pages object
* @constructor
*/
function Pages() {
    this.current = new CurrentPage();
    this.lastVisitedLink = null;
}

Pages.prototype = {
    /**
    * A function to get and set items in the storage
    * @param {string} key - the key of the item to be stored/retrieved
    * @param {string} value - the value of the item to be stored
    * @returns {string} - the value of the item stored/retrieved
    */
    prop: function () {
        if (arguments.length > 1) {
            storage.setItem(arguments[0], arguments[1]);
            return arguments[1];
        }

        return storage.getItem(arguments[0]);
    },

    /**
    * Removes an item from the storage
    * @param {string} arguments[0] - the item to be removed from the storage
    */
    delete: function () {
        storage.removeItem(arguments[0]);
    }
};

/**
* Constructor for the PageHistory class
* Initializes the page history and replaces the current URL
*/
function PageHistory() {
    this.init();
    this.replace({ url: this.current.url });
}

PageHistory.prototype = {
    /**
    * Custom history object to store page navigation history
    * @returns {Object} customHistory - an object containing the page navigation history
    */
    init: function () {
        var customHistory = {};
        try {
            customHistory = JSON.parse(window.pages.prop("custom_history"));
        } catch (error) {
            return {};
        }

        Object.assign(this, customHistory);

        this.current = {
            url: decodeURI(window.location.href)
        };

        // The below code checks if the current page is the same as the last page in the history.
        // If they are the same, it means that the page was reloaded, and in such cases, the state set to reloaded and the history should not be updated.
        if (customHistory && customHistory.current.url == this.current.url) {
            this.prev = customHistory.prev;
            this.state = "page:reloaded";
            return;
        }

        // Retrieves the padding value from the custom history.
        // If the padding value is not set, it defaults to the length of the history.
        // This padding value is important for keeping the window history object and custom history object synchronized.
        var padding = customHistory ? customHistory.padding : window.history.length;
        var allUrls = customHistory ? customHistory.all : [];

        // Find the index of the current page in the history.
        // If the page is not found, it means that the page is new and should be added to the history.
        var pageIndex = allUrls.findIndex(function (h) {
            return h.url == decodeURI(window.location.href);
        });

        // Determines the state of the page by checking the existence of the URL in the custom history object and the length of the native history.
        // If the page is new, it is pushed to the history and the state is set as "pushed".
        // Otherwise, if the page is popped from the history via the back button, the state is set as "popped".
        if (!customHistory || (customHistory
            && customHistory.current.url !== decodeURI(window.location.href))) {
            if (pageIndex < 0 || window.history.length >= allUrls.length + padding) {
                allUrls.push(this.current);
                this.state = "page:pushed";
                pageIndex = allUrls.length - 1;
            } else {
                this.state = "page:popped";
            }
        }

        // Sets the previous page URL to the page before the current page.
        // However, if the current page is the first page in the history, then the previous page URL is set to empty.
        this.prev = allUrls.length > 1 ? allUrls[pageIndex - 1] : {};
        this.all = allUrls;
        this.padding = padding;
        this.pageIndex = pageIndex;

        this.updateHistory();
    },

    /**
    * Push a new state to the browser history and initialize the page
    */
    push: function () {
        window.history.pushState.apply(window.history, getState(arguments));
        if (decodeURI(window.location.href) == this.current.url) {
            return;
        }

        this.init();

        window.pages = new Pages() || {};
    },

    /**
    * Replaces the current page state in the history
    * @param {Object} arguments - the arguments object
    * @returns {Object} - the new page state
    */
    replace: function () {
        window.history.replaceState.apply(window.history, getState(arguments));

        var replacingUrl = this.current.url;

        // This code updates the current page URL to the replaced URL and also updates the browsing history accordingly.
        this.current = {url: decodeURI(window.location.href)};
        this.all[this.pageIndex] = this.current;
        this.updateHistory();

        // Moves the page events associated with the page to the replaced one by copying it.
        window.pages.current.events.clone(toHash(replacingUrl));
    },

    /**
    * Stores the custom history of the page in window.pages
    */
    updateHistory: function () {
        var customHistory = {
            current: this.current,
            prev: this.prev,
            all: this.all,
            padding: this.padding,
            pageIndex: this.pageIndex
        };

        window.pages.prop("custom_history", JSON.stringify(customHistory));
        window.pages = new Pages() || {};
    }
};

window.pages = new Pages();
window.pageHistory = new PageHistory();
