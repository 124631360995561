window.jQuery = window.$ = require("jquery");
var processInclude = require("base/util");

// eslint-disable-next-line no-undef
$(document).ready(function () {
    processInclude(require("base/components/menu"));
    processInclude(require("base/components/cookie"));
    processInclude(require("./components/footer"));
    processInclude(require("./components/miniCart"));
    processInclude(require("base/components/collapsibleItem"));
    processInclude(require("./components/search"));
    processInclude(require("base/components/clientSideValidation"));
    processInclude(require("base/components/countrySelector"));
    processInclude(require("base/components/toolTip"));
    processInclude(require("./components/promoSlider"));
    processInclude(require("./ageConsent/ageConsent"));
    processInclude(require("./communicationPref/communicationPreferences"));
    processInclude(require("./components/consentTracking"));
    processInclude(require("plugin_wishlists/product/wishlistHeart"));
    processInclude(require("plugin_wishlists/product/wishlist"));
});

// eslint-disable-next-line no-undef
if ($(".page").data("backToScrollEnabled")) {
    require("app_ballantynes/utils/pages");
}

require("base/thirdParty/bootstrap");
require("base/components/spinner");
